const getLangContent = (lang) => {
  const langSuffix = lang ? `-${lang}` : ''
  return {
    general: require(`content/general${langSuffix}`),
    navigation: require(`content/navigation${langSuffix}`),
    hero: require(`content/hero${langSuffix}`),
    zepetoHero: require(`content/zepeto-hero${langSuffix}`),
    zepetoVideoBooth: require(`content/zepeto-video-booth${langSuffix}`),
    zepetoPhotoBooth: require(`content/zepeto-photo-booth${langSuffix}`),
    zepetoPrizes: require(`content/zepeto-prizes${langSuffix}`),
    zepetoTalent: require(`content/zepeto-talent${langSuffix}`),
    zepetoRules: require(`content/zepeto-rules${langSuffix}`),
    robloxHero: require(`content/roblox-hero${langSuffix}`),
    robloxConcert: require(`content/roblox-concert${langSuffix}`),
    robloxPrizes: require(`content/roblox-prizes${langSuffix}`),
    robloxTalent: require(`content/roblox-talent${langSuffix}`),
    robloxRules: require(`content/roblox-rules${langSuffix}`),
  }
}

const allContent = {
  en: getLangContent(),
  kr: getLangContent('kr'),
  jp: getLangContent('jp'),
}

const getContent = (lang) => allContent[lang] || allContent.en

export default getContent
