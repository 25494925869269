import './src/styles/index.sass'
import "src/state"

if (window.location.search.includes('lang')) {
  const params = new URLSearchParams(window.location.search.replace('?', ''))
  window.state.setCurrentLang(params.get('lang'))
}
else {
  const hostParts = window.location.host.split('.')
  window.state.setCurrentLang(hostParts.length > 2 ? hostParts[0] : 'en')
}
