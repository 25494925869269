import create from 'zustand'
import getContent from 'content/all'

export const useStore = create((set) => ({
  currentSectionName: null,
  currentLang: null,
  content: null,
  setCurrentSection: (currentSectionName) => set({ currentSectionName }),
  setCurrentLang: (currentLang) => set({
    currentLang,
    content: getContent(currentLang || 'en'),
  }),
}))

try {
  Object.defineProperty(window, 'state', {
    get: function () {
      return useStore.getState()
    },
  })
} catch (err) {
  // noop
}
